<!-- 已审批 -->

<template>
	<list :layout1="layout1" orderStatus="1,2"></list>
</template>

<script>
import list from '../list/index.vue';
export default {
	name: '',
	components: { list },
	data() {
		return {
			layout1: [
				{
					label: '审批人',
					key: 'approvalUser'
				},
				{
					label: '审批时间',
					key: 'approvalTimeText'
				}
			]
		};
	},
	created() {},
	mounted() {},
	methods: {}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
</style>
